<template>
  <div class="questionButtons">
    <div class="question-btn btn-false" @click="nextQuestionsFalse"><span class="false"></span></div>
    <div class="question-btn btn-true" @click="nextQuestionsTrue"><span class="true"></span></div>
  </div>
</template>

<script>
export default {
  name: 'questionButtons',
  methods: {
    nextQuestionsFalse (event) {
      this.$emit('clicked', false)
    },
    nextQuestionsTrue (event) {
      this.$emit('clicked', true)
    }
  }
}
</script>

<style lang="scss">

.questionButtons {
  margin-top: 70px;

  @media all and (max-width: 768px) {
    margin-top: 40px;
  }

  .question-btn {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin: 10px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    position: relative;

    @media all and (max-width: 540px) {
      width: 40px;
      height: 40px;
    }

    &:hover {
      transition: all 0.25s;
      color: #fff;

      &.btn-false{
        background-color: #FF2424;
      }
      &.btn-true{
        background-color: #59F87F;
      }
    }

    &.btn-false {
      border: #FF2424 4px solid;
      .false{
        position: absolute;
        left: 50%;
        top: 50%;
        width: 32px;
        height: 32px;
        transform: translate(-50%, -50%);
        translate: 25ms all;

        @media all and (max-width: 540px) {
          height: 25px;
          width: 25px;
        }
      }
      .false:before, .false:after {
        position: absolute;
        content: ' ';
        height: 33px;
        width: 4px;
        background-color: #FF2424;
        margin-left: -2px;

        @media all and (max-width: 540px) {
          height: 25px;
          width: 4px;
        }
      }
      .false:before {
        transform: rotate(45deg);
      }
      .false:after {
        transform: rotate(-45deg);
      }

      &:hover .false:before,
      &:hover .false:after {
        background-color: #fff;
      }

    }
    &.btn-true {
      border: #59F87F 4px solid;
      .true{
        display: inline-block;
        position: absolute;
        top: 15%;
        left: 35%;
        transform: rotate(45deg);
        height: 32px;
        width: 15px;
        border-bottom: 4px solid #59F87F;
        border-right: 4px solid #59F87F;

        @media all and (max-width: 540px) {
          height: 20px;
          width: 8px;
        }
      }

      &:hover .true {
        border-color:#fff;
      }
    }
  }
}
</style>
