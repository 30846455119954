<template>
  <div class="counter">
      Question <b>{{ count }}/{{ total }}</b>
  </div>
</template>

<script>
export default {
  name: 'questionsCounter',
  props: {
    count: Number,
    total: Number
  }
}
</script>

<style lang="scss">
  .counter {
    font-size: 70px;
    margin-bottom: 70px;

    @media all and (max-width: 768px) {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    @media all and (max-width: 540px) {
      font-size: 30px;
    }

    b {
      font-family: 'Fredoka One', cursive;
      font-weight: normal;
    }
  }
</style>
