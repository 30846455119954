<template>
  <transition name="fade" mode="out-in" appear>
  <section v-if="imgSrc" class="sideImage" :key="imgSrc">
      <img :src="imgSrc" :alt="altText">
  </section>
  </transition>
</template>

<script>
export default {
  name: 'sideImage',
  props: {
    imgSrc: String,
    altText: String
  }
}
</script>

<style lang="scss">
  .sideImage {
    overflow: hidden;
    position: fixed;
    height: 100vh;
    left: 50vw;
    top: 0;
    bottom: 0;
    right:0;

    @media all and (max-width: 768px) {
      display: none;
    }

    img {
      width: 100%;
      min-height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      object-fit: cover;
    }
  }
</style>
