<template>

  <main class="question page">
    <section class="mainContent">
      <sectionHeader/>
      <transition name="fade" mode="out-in" appear>
        <div :key="index" class="question_wrapper">

        <div class="mobile-img">
          <img :src="questions[index].img"/>
        </div>
        <questionsCounter :count="index+1" :total="questions.length"/>
        <h2 class="question-text"><b>{{ index+1 }}.</b> <span v-html="questions[index].question"></span></h2>
        <questionsButtons @clicked="nextQuestion"/>

        <div class="quiz-navigation">
          <span v-if="index > 0" class="prev" @click="prevQuestion">Question précédente</span>
          <span class="reset" @click=" resetQuiz">Redémarrer le quizz</span>
        </div>
      </div>
      </transition>
    </section>
    <sideImage :imgSrc="questions[index].img"/>

  </main>

</template>

<script>
import sectionHeader from '@/components/sectionHeader.vue'
import sideImage from '@/components/sideImage.vue'
import questionsCounter from '@/components/questions/questionsCounter.vue'
import questionsButtons from '@/components/questions/questionsButtons.vue'
import { mixins } from '../mixins/mixins.js'

export default {
  name: 'Questions',
  mixins: [mixins],
  data () {
    return {
      index: 0
    }
  },
  components: {
    sectionHeader,
    sideImage,
    questionsButtons,
    questionsCounter
  },
  computed: {
    questions () {
      return this.$store.state.questions
    }
  },
  methods: {
    nextQuestion (value) {
      if (value) {
        this.$store.state.userResponses[this.index] = this.index + 1
      } else {
        this.$store.state.userResponses[this.index] = ''
      }
      // console.log(this.$store.state.userResponses)

      if (this.index + 1 < this.$store.state.questions.length) {
        this.index++
      } else {
        this.$router.push('form')
      }
    },
    prevQuestion () {
      console.log('previous')
      this.$store.state.userResponses.splice(this.index)
      this.index--
      // console.log(this.$store.state.userResponses)
    }
  }
}
</script>

<style lang="scss">
  h2.question-text {
    font-size: 45px;
    font-weight: 300;
    font-family: 'Titillium Web', sans-serif;

    @media all and (max-width: 768px) {
      font-size: 28px;

      br {
        display: none;
      }
    }

    @media all and (max-width: 540px) {
      font-size: 20px;
    }
  }

  .question_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 80vh;

    @media all and (min-width: 1024px) {
      height: 550px;
    }

    .questionButtons {
        margin-top: auto;
      }
  }

  .quiz-navigation {
    margin-top: 30px;
    text-decoration: underline;
    cursor: pointer;

    .prev {
      margin-right: 10px;
    }
  }
</style>
